<template>
  <v-card rounded="0" color="#f3f2ed" class="pt-4">
    <v-container>
      <v-card>
        <v-container>
          <h4 class="h35" style="color:#185454">History of the Daily Community Program in Luanda (Fubu)</h4>
          <v-divider class="mb-2" />
          <v-row>
            <v-col cols="12" lg="6">
              <h5 class="h5 mb-4">After seeing the devastating effects of poverty on the children of Luanda, Hope and Angels Foundation established a Community Program in 2017 in one of the most disadvantaged neighbourhoods of that city, Fubu. A large number of families in Luanda are single-parent families. Many single mothers set off around 5:00 a.m. every day in search for what’s needed for their family’s daily survival. In many cases, they spend their days scouring through garbage dumps and containers.</h5>
              <h5 class="h5 my-4">Their children, however, are left without care, supervision or protection. They fend for themselves every day. They have no hope of ever attending school. Spending their days as beggars, they are exposed to abuse and introduced to crime, prostitution and human trafficking.</h5>
              <h5 class="h5 my-4">Currently, Hope and Angels Foundation provides a loving and protective environment for 57 impoverished children.</h5>
            </v-col>
            <v-col cols="12" lg="6">
              <v-carousel
                cycle
                height="auto"
                class="pa-0"
                delimiter-icon="mdi-minus"
              >
                <v-carousel-item
                  v-for="(item,i) in items"
                  :key="i"
                  :src="item.src"
                  reverse-transition="fade-transition"
                  fade
                  eager
                >
                  <v-img :src="item.src" eager/>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </v-card>
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('@/assets/images/luanda/Picture1.png')
        },
        {
          src: require('@/assets/images/luanda/Picture2.png')
        },
        {
          src: require('@/assets/images/luanda/Picture3.jpg')
        },
        {
          src: require('@/assets/images/luanda/Picture4.png')
        },
        {
          src: require('@/assets/images/luanda/Picture5.png')
        },
        {
          src: require('@/assets/images/luanda/Picture6.png')
        },
        {
          src: require('@/assets/images/luanda/Picture7.png')
        },
        {
          src: require('@/assets/images/luanda/Picture8.jpg')
        },
        {
          src: require('@/assets/images/luanda/Picture9.jpg')
        }
      ]
    }
  }
}
</script>