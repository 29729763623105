<template>
  <div>
    <TopParallax
      title="Daily Community Program in Luanda"
      :img-src="imgSrc"
    />
    <ChildrenBar />
    <LuandaContent />
  </div>
</template>

<script>
import TopParallax from '@/components/TopParallax.vue'
import ChildrenBar from '@/components/hope/ChildrenBar.vue'
import LuandaContent from '@/components/hope/LuandaContent.vue'

export default {
  components: {
    TopParallax,
    ChildrenBar,
    LuandaContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/slide2.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Luanda'
      }
    }
  }
}
</script>
